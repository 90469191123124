@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

main {
  flex: 2;
}
